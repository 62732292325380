// FIXME - how to get `keyof typeof handlers`???  https://github.com/microsoft/TypeScript/issues/25987

export enum CommandNames {
  toggleUserActive = 'toggleUserActive',
  setProfileImage = 'setProfileImage',
  createUser = 'createUser',
  validateMerchantForRegistration = 'validateMerchantForRegistration',
  createMerchantUser = 'createMerchantUser',
  registerMerchantUser = 'registerMerchantUser',
  deleteMerchantUser = 'deleteMerchantUser',
  createLead = 'createLead',
  createResource = 'createResource',
  updateResource = 'updateResource',
  updateResourceOrder = 'updateResourceOrder',
  createAssociationBetweenApplications = 'createAssociationBetweenApplications',
  removeAssociationBetweenApplications = 'removeAssociationBetweenApplications',
  resetPassword = 'resetPassword',
  setDisposition = 'setDisposition',
  getPortalToken = 'getPortalToken',
  downloadPdf = 'downloadPdf',
  generatePdf = 'generatePdf',
  getAlgoliaSearchKey = 'getAlgoliaSearchKey',
  sendApplicationDispute = 'sendApplicationDispute',
  sendAppNotification = 'sendAppNotification',
  createAnnouncement = 'createAnnouncement',
  getResidualAdjustments = 'getResidualAdjustments',
  getResidualGroupAdjustments = 'getResidualGroupAdjustments',
  saveResidualManualAdjustments = 'saveResidualManualAdjustments',
  boardAccount = 'boardAccount',
  createAgent = 'createAgent',
  sendAgentLogInEmail = 'sendAgentLogInEmail',
  sendMerchantLogInEmail = 'sendMerchantLogInEmail',
  decryptApplicationField = 'decryptApplicationField',
  transferApplication = 'transferApplication',
  getTransferAuthToken = 'getTransferAuthToken',
  executeUtility = 'executeUtility',
  runScorecardService = 'runScorecardService',
  registerNowEmail = 'registerNowEmail',
  toggleGroupActive = 'toggleGroupActive',
  welcomeEmail = 'welcomeEmail',
  welcomeEmailWithResetPassword = 'welcomeEmailWithResetPassword',
  runResidualCalculations = 'runResidualCalculations',
  getSiteJumpToken = 'getSiteJumpToken',
  getDocumentsUploadToken = 'getDocumentsUploadToken',
  getDocumentsUploadPlaidLinkToken = 'getDocumentsUploadPlaidLinkToken',
  selectDocumentsUploadPlaidAccount = 'selectDocumentsUploadPlaidAccount',
  getDocumentsUploadFinicityLinkToken = 'getDocumentsUploadFinicityLinkToken',
  getDocumentsUploadFinicityAccounts = 'getDocumentsUploadFinicityAccounts',
  getDocumentsUploadFinicityAchDetail = 'getDocumentsUploadFinicityAchDetail',
  getDocumentsUploadFinicityInstitution = 'getDocumentsUploadFinicityInstitution',
  selectDocumentsUploadFinicityLinkBankAccount = 'selectDocumentsUploadFinicityLinkBankAccount',
  selectDocumentsUploadFinicityAccount = 'selectDocumentsUploadFinicityAccount',
  getVerifiedDocumentsUploadToken = 'getVerifiedDocumentsUploadToken',
  markAllNotificationsAsRead = 'markAllNotificationsAsRead',
  moveAttachments = 'moveAttachments',
  createDownloadFilesRequest = 'createDownloadFilesRequest',
  archivateAttachments = 'archivateAttachments',
  sendMfaCode = 'sendMfaCode',
  mfaLoginUsePass = 'mfaLoginUsePass',
  confirmMfaCode = 'confirmMfaCode',
  applicationUnlinkFinicity = 'applicationUnlinkFinicity',
  applicationTransferToGroup = 'applicationTransferToGroup',
  getUsersProfiles = 'getUsersProfiles',
  resetMerchantRegistrationAttempts = 'resetMerchantRegistrationAttempts',
}
